import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";

@Injectable({
    providedIn: "root",
})
export class BlogService {
    // TODO: cachear os resultados, com expiração diária
    constructor(private readonly http: HttpClient) {}

    getPosts(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/blog`);
    }
}
