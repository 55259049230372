<section class="bg-white py-5">
    <div class="container text-center single-col-max-width">
        <h2 class="heading fw-bold mb-4">Um pequeno blog sobre desenvolvimento de software</h2>
        <div class="intro">
            <p>Bem vindo ao meu blog pessoal.</p>
            <p>
                Aqui é onde eu compartilho um pouco do que vou aprendendo na minha jornada como engenheiro de software, porque
                como já diria o "velho deitado"... <i>A melhor forma de aprender é ensinando</i>.
            </p>
        </div>
    </div>
    <!--//container-->
</section>

<!-- TODO: Incluir um loading enquanto carrega os posts, de forma que não trave a navegação -->
<!-- TODO: Colocar uma forma de pesquisar posts no blog -->
<!-- TODO: Colocar uma forma de filtrar por tag -->
<section class="blog-list px-3 py-5 p-md-5">
    <div class="container">
        <div class="row">
            <div class="col-md-4 mb-3" *ngFor="let blogPost of blogPosts">
                <app-card-blog-post
                    [title]="blogPost.title"
                    [featureImageUrl]="blogPost.featureImageUrl"
                    [url]="blogPost.url"
                    [publishedAt]="blogPost.publishedAt"
                    [tags]="blogPost.tags"
                ></app-card-blog-post>
            </div>
        </div>
        <!--//row-->

        <!-- TODO: Incluir paginação -->
        <nav class="blog-nav nav nav-justified my-5">
            <a class="nav-link-prev nav-item nav-link d-none rounded-left" href="#">
                Previous
                <i class="arrow-prev fas fa-long-arrow-alt-left"></i>
            </a>
            <a class="nav-link-next nav-item nav-link rounded" href="#">
                Próxima página
                <i class="arrow-next fas fa-long-arrow-alt-right ms-2"></i>
            </a>
        </nav>
    </div>
</section>
